// React & Dependencies
import { useState, useEffect } from 'react';

// Homes

const useRecosBookmarks = ({ restClient, bookmarkedItemsFromState }) => {
  if (!bookmarkedItemsFromState) return {};

  const [bookmarkedItems, setBookmarkedItems] = useState(bookmarkedItemsFromState?.items || []);

  const handleBookmarkChange = e => {
    if (e.data.type === 'bookmark:add' && e.data.id) {
      setBookmarkedItems((prevBookmarkedItems) => [...prevBookmarkedItems, e.data.id]);
    }
    if (e.data.type === 'bookmark:remove' && e.data.id) {
      setBookmarkedItems((prevBookmarkedItems) => [...prevBookmarkedItems].filter((itemId) => itemId !== e.data.id));
    }
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('message', handleBookmarkChange);
    }
    return () => {
      window.removeEventListener('message', handleBookmarkChange);
    };
  }, []);

  const updateBookmarkCallback = ({
    isBookmarked,
    setIsFetchingBookmark,
    itemProps,
  }) => {
    const { id: itemId } = itemProps;
    const { _csrf: csrf } = restClient.config.params;
    const verb = isBookmarked ? 'delete' : 'post';

    restClient[verb](`/bookmarks/${itemId}`, {
      loginParams: {
        loginType: 'FAVORITE',
        item_id: itemId,
        go: `${globalThis.location.href}gz/home/api/bookmarks/${itemId}?_csrf=${csrf}`,
      },
      params: { go: `${globalThis.location.href}` },
    })
      .then(() => {
        if (window) {
          const bookmarkAction = isBookmarked ? 'remove' : 'add';
          window.parent.postMessage({ type: `bookmark:${bookmarkAction}`, id: itemId }, '*');
        }
        setIsFetchingBookmark(false);
      })
      .catch((error) => {
        setIsFetchingBookmark(false);
        if (error.response && error.response.status === 401) {
          const redirectUrl = error.response.data.url;
          if (redirectUrl) {
            window.location.assign(redirectUrl);
          }
        }
      });
  };

  return {
    bookmarkedItems,
    updateBookmarkCallback,
  };
};

export default useRecosBookmarks;
