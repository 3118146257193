import React from 'react';
import { node, string, object, bool, number, shape } from 'prop-types';
import { PolycardContext } from '@polycard/card';
import { DEVICE_TYPE } from '../../../../utils/constants';

const namespace = 'polycard-web';
function WrappedContext({
  children = null,
  contextValue = null,
  deviceType = DEVICE_TYPE.MOBILE,
  lazy = false,
  is_polycard = null,
  numberOfItems = null,
  type = null,
  country = null,
}) {
  return is_polycard ? (
    <div className={`${namespace}-lib`}>
      <PolycardContext
        contextValue={{ ...contextValue, type }}
        deviceType={deviceType}
        countPreloadImages={!lazy ? numberOfItems : undefined}
        thousandSeparator={country?.thousands_separator}
        decimalSeparator={country?.decimal_separator}
      >
        {children}
      </PolycardContext>
    </div>
  ) : (
    <>{children}</>
  );
}

WrappedContext.propTypes = {
  children: node,
  contextValue: object,
  deviceType: string,
  is_polycard: bool,
  lazy: bool,
  numberOfItems: number,
  type: string,
  country: shape({
    thousands_separator: string,
    decimal_separator: string,
  }),
};

export default WrappedContext;
