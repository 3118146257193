import React from 'react';
import { arrayOf, string, number, shape } from 'prop-types';
import Image from '../../image';

const minQuantityCountItems = 3;

function CartSeed(props) {
  const { items, cart_item_count, title, target_url, sub_title } = props;
  const cartItemsLength = items.length;
  const imagesQuantity = cart_item_count > 4 ? 'more' : items.length;
  const moreItemsCount = cart_item_count - minQuantityCountItems;
  const colsLength = Math.min(items.length, 2);
  return (
    <div className="ui-card-seed__cart">
      <div className="ui-card-seed__image-container">
        <div className={`quantity-${imagesQuantity}`}>
          {items.slice(0, colsLength).map((seed, i) => (
            <div className="col-image" key={seed.id}>
              {items
                .slice(
                  Math.floor(cartItemsLength / 2) * i,
                  Math.floor(cartItemsLength / 4) + 1 + Math.ceil(cartItemsLength / 2) * i,
                )
                .map((item) => (
                  <Image
                    {...item.picture}
                    lazyLoad
                    key={item.id}
                    alt={item.title}
                  />
                ))}
              {i === 1 && imagesQuantity === 'more' && (
                <div className="quantity">+ {moreItemsCount}</div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="ui-card-seed__content-and-hidden">
        <div className="ui-card-seed__content">
          <h2 className="ui-card-seed__title">
            <a href={target_url} className="ui-card-seed__link">
              {title}
            </a>
          </h2>
          <p className="ui-card-seed__subtitle">{sub_title}</p>
        </div>
        <div className="ui-card-seed__hidden" />
      </div>
    </div>
  );
}

CartSeed.propTypes = {
  items: arrayOf(shape({ id: string.isRequired })).isRequired,
  cart_item_count: number.isRequired,
  target_url: string.isRequired,
  title: string.isRequired,
  sub_title: string.isRequired,
};

export default CartSeed;
